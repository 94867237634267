:root {
  --border-width: 2px;
}
.header {
  display: flex;
  justify-content: space-between;
  height: 64px;
  width: 100%;
  align-items: center;
}
.nav {
  display: flex;
  gap: 24px;
  height: 100%;
}
.link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #000;
  border-bottom: solid var(--border-width) transparent;
  white-space: nowrap;
}
.link span {
  position: relative;
  top: var(--border-width);
}
.link[data-current="true"] {
  color: var(--blue-600);
  border-bottom: solid var(--border-width);
}
.avatarLabel {
  margin-right: 16px;
}
