.container {
  width: 100%;
  max-width: 320px;
  background: #fff;
  border-radius: 16px;
  margin: 120px auto 0;
}
.heading {
  display: flex;
  justify-content: center;
  margin-bottom: 16px !important;
}
.form {
  padding: 24px;
}
.label {
  margin-bottom: 4px;
  font-size: 14px;
}
.input {
  margin-bottom: 16px;
}
.input input:-webkit-autofill,
.input input:-webkit-autofill:hover,
.input input:-webkit-autofill:focus,
.input input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}
.error {
  margin-bottom: 20px;
}
.submit {
  margin-top: 24px;
  margin-bottom: 0;
}
