.container {
  display: flex;
  flex-direction: column;
  padding: 12px 0;
}

.link {
  position: relative;
  border: solid 1px #e3e3e3;
  padding: 12px 20px 12px 64px;
  border-radius: 8px;
  line-height: 20px;
}
.link:hover {
  background: var(--grey-100);
}
.link + .link {
  margin-top: 8px;
}
.link::after {
  display: block;
  content: attr(data-fileSize);
  color: var(--grey-600);
}

.icon {
  position: absolute;
  top: 12px;
  left: 16px;
  font-size: 42px;
  margin-right: 4px;
}
