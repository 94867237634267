html {
  scroll-behavior: smooth;
}

body {
  background: #e7e7e7;
}

.header {
  background: #fff;
  display: flex;
  align-items: center;
  width: 100%;
  height: 64px;
  padding: 0 32px;
}

.main {
  margin: 0 auto;
  padding: 24px;
  min-height: calc(100dvh - 128px);
  width: 100%;
}
