.footer {
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  height: 64px;
  flex-direction: row-reverse;
  font-size: 14px;
  width: 100%;
}
.nav ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}
.nav li {
  display: flex;
  align-items: center;
}
.nav li::after {
  content: '';
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 4px;
  margin: 0 8px;
  background: currentColor;
}
.nav li:last-child::after {
  display: none;
}
.nav a {
  text-decoration: none;
  color: #262626;
}

@media screen and (max-width: 1100px) {
  .footer {
    flex-direction: column;
    height: auto;
    padding: 16px 0;
    text-align: center;
    line-height: 20px;
  }
  .nav ul {
    align-items: center;
    flex-direction: column;
    gap: 4px;
    margin: 8px 0 16px;
  }
  .nav li::after {
    display: none;
  }
}

