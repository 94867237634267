.root {
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
}
.main {
  background: #fff;
  border-radius: 12px;
  padding: 24px;
}
.root ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.root ul li {
  margin-bottom: 4px;
}
.icon {
  width: 24px;
  height: 24px;
  border-radius: 8px;
  margin-right: 4px;
  background: var(--grey-100);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: var(--blue-600);
}
.section:not(:last-child) {
  border-bottom: solid 1px #e3e3e3;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
